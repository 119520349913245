import { useLocation } from 'react-router-dom';
import {
  initialize as initializeAnalytics,
  InitializeProps,
} from '@getpopsure/analytics';
import { initialize as initializeCookieTracker } from '@getpopsure/tracker';
import { ConsentObject, cookieType } from '@getpopsure/cookie-consent';

export { SourceTracker } from './SourceTracker';

export function useQuery() {
  return Object.fromEntries(new URLSearchParams(useLocation().search));
}

const cookieTrackerOptions =
  process.env.NODE_ENV === 'development'
    ? { cookieAttributes: { expires: 60, domain: 'localhost' } }
    : {};

/**
 * GTM environments are meant to be used only in development/staging environments.
 * Therefore, check the current env and don't add the params in production
 */
const isProduction = process.env.REACT_APP_ENVIRONMENT === 'production';
const googleTagManagerOptions: InitializeProps['googleTagManager'] = {
  containerId: process.env.REACT_APP_GTM_ID ?? '',
  ...(isProduction
    ? {}
    : {
        auth: process.env.REACT_APP_GTM_AUTH,
        preview: process.env.REACT_APP_GTM_PREVIEW,
      }),
};

initializeCookieTracker(cookieTrackerOptions);

export const initializeTrackers = (consent: ConsentObject) => {
  let trackerOptions: InitializeProps = {
    snowplow:
      process.env.REACT_APP_COLLECTOR_APP_ID &&
      process.env.REACT_APP_COLLECTOR_URL
        ? {
            appId: process.env.REACT_APP_COLLECTOR_APP_ID,
            collector: process.env.REACT_APP_COLLECTOR_URL,
          }
        : undefined,
  };

  if (consent[cookieType.analytics_storage] === 'granted') {
    trackerOptions = {
      ...trackerOptions,
      googleAnalytics: process.env.REACT_APP_GA_TRACKING
        ? {
            trackingCode: process.env.REACT_APP_GA_TRACKING,
          }
        : undefined,
    };
  }

  if (
    consent[cookieType.ad_storage] === 'granted' ||
    consent[cookieType.analytics_storage] === 'granted' ||
    consent[cookieType.ad_personalization] === 'granted' ||
    consent[cookieType.ad_user_data] === 'granted'
  ) {
    trackerOptions = {
      ...trackerOptions,
      googleTagManager: googleTagManagerOptions,
    };
  }

  initializeAnalytics(trackerOptions);
};
