import { Provider } from 'react-redux';
import { persistor, store } from 'reducers';
import { Route, Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import * as Sentry from '@sentry/browser';
import initI18n from 'i18n';
import { useEffect } from 'react';

import { version } from '../package.json';
import browserHistory from 'util/browserHistory';

import App from './app';
import * as serviceWorker from './serviceWorker';
import 'style.scss';
import { useTracker } from '@getpopsure/analytics';
import { populateTrackingObjectFromUrl } from '@getpopsure/tracker';
import { useFeatureFlagService } from 'util/featureFlags';
import {
  consentCookieExists,
  getCurrentConsent,
} from '@getpopsure/cookie-consent';
import { initializeTrackers } from './tracker';

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://e37b8e15d5e64a079bf4fa0971365aac@sentry.io/1727933',
    environment: process.env.REACT_APP_ENVIRONMENT,
    release: version,
    ignoreErrors: [
      // Happens when ad blockers block Bing trackers
      'ReferenceError: UET is not defined',

      // Not related to our codebase
      "ReferenceError: Can't find variable: zaloJSV2",
    ],
  });
}

/**
 * If the user hasn't given consent, we'll show the cookie consent banner
 * The trackers will be initialized after the user saves its preferences
 */
if (consentCookieExists()) {
  initializeTrackers(getCurrentConsent());
}

const Root = () => {
  useFeatureFlagService();
  useTracker();

  useEffect(() => {
    initI18n();
  }, []);

  useEffect(() => {
    populateTrackingObjectFromUrl();
  }, []);
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router history={browserHistory}>
          <Route component={App} />
        </Router>
      </PersistGate>
    </Provider>
  );
};

export default Root;

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
